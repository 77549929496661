<template>
  <div
    class="p-3 mb-4"
    style="border-radius: 10px; border: 1px solid #d5dfe4; background-color: #f8f8f8"
  >
    <b-form
      ><div class="mb-4 d-flex justify-content-between align-items-center">
        <h5 class="fw-500">
          {{ value.id || idxnum + 1 ? `Кейс №${value.id || idxnum + 1}` : 'Новый кейс' }}
        </h5>
        <span
          v-if="len > 1"
          @click="$emit('delete', value.id)"
          style="cursor: pointer"
          class="gray-text fs-12"
          >Удалить</span
        >
      </div>
      <b-form-group label="Аккаунт блогера"
        ><b-form-input
          style="height: 40px"
          v-model="$v.value.instaname.$model"
          :class="{ error: $v.value.instaname.$anyError }"
        ></b-form-input></b-form-group
      ><b-form-group
        ><left-pointed-radio-group
          buttonStyle="height: 40px !important"
          v-model="$v.value.kind.$model"
          :error="$v.value.kind.$anyError"
          :options="infoProductsOptions.actions.POST.kind.choices"
        ></left-pointed-radio-group></b-form-group
      ><b-form-group label="Название и короткое описание инфопродукта."
        ><b-form-textarea
          v-model="$v.value.description.$model"
          :class="{ error: $v.value.description.$anyError }"
          style="min-height: 180px"
        ></b-form-textarea></b-form-group
      ><b-form-group label="Примерная дата запуска"
        ><b-form-datepicker
          style="padding: 3.3px 0"
          placeholder="Выберите дату"
          locale="ru"
          v-model="$v.value.launch_date.$model"
          :class="{ error: $v.value.launch_date.$anyError }"
        ></b-form-datepicker></b-form-group
      ><b-form-group label="Средний чек инфопродукта."
        ><div class="d-flex justify-content-between">
          <div style="width: 50%" class="rouble-inp mr-2">
            <b-form-input
              style="height: 40px"
              placeholder="От"
              class=""
              v-model="$v.value.price_min.$model"
              :class="{ error: $v.value.price_min.$anyError }"
            ></b-form-input>
          </div>
          <div style="width: 50%" class="rouble-inp ml-2">
            <b-form-input
              style="height: 40px"
              placeholder="До"
              class=""
              v-model="$v.value.price_max.$model"
              :class="{ error: $v.value.price_max.$anyError }"
            ></b-form-input>
          </div></div></b-form-group
      ><b-form-group label="Итоговый оборот в рублях по инфопродукту."
        ><b-form-input
          style="height: 40px"
          v-model="$v.value.turnover.$model"
          :class="{ error: $v.value.turnover.$anyError }"
          class="w-100 rouble-inp"
          placeholder="От"
        ></b-form-input></b-form-group
      ><b-form-group label="Охваты блогера"
        ><b-form-input
          style="height: 40px"
          v-model="$v.value.blogger_coverage.$model"
          :class="{ error: $v.value.blogger_coverage.$anyError }"
          type="number"
        ></b-form-input></b-form-group
      ><b-form-group
        label="Если есть скриншоты, подтверждающие эти данные - прикрепите их (это могут быть скриншоты из кабинета Геткурса и т.д.)."
      >
        <screenshot-loader
          v-model="value.screenshots"
          uploadUrl="/api/producing/producing_cases/upload_screenshot/"
          ><template #uploadButton="{ handler }"
            ><b-button
              :class="{ error: $v.value.screenshots.$anyError }"
              style="height: 40px"
              variant="primary"
              class="w-100"
              @click="
                () => {
                  handler();
                }
              "
              >Загрузить</b-button
            ></template
          ></screenshot-loader
        ></b-form-group
      ><b-form-group
        class="input__symbol--count position-relative mb-5"
        :symbolCount="(value.processes_involved || '').length"
        :symbolLimit="220"
        label="Коротко укажите в каких процессах при запуске инфопродукта вы были задействованы."
        ><b-form-textarea
          maxlength="220"
          v-model="$v.value.processes_involved.$model"
          :class="{ error: $v.value.processes_involved.$anyError }"
          style="min-height: 180px"
        ></b-form-textarea></b-form-group
      ><b-form-group
        ><b-checkbox
          v-model="$v.value.blogger_can_confirm.$model"
          :class="{ error: $v.value.blogger_can_confirm.$anyError }"
          >Блогер может подтвердить, что работал с вами</b-checkbox
        ></b-form-group
      ></b-form
    >
    <b-button
      style="height: 40px"
      @click="$emit('copy')"
      class="w-100 mt-3"
      variant="outline-default"
      >Дублировать кейс</b-button
    >
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required, minLength, sameAs, requiredIf, email } from 'vuelidate/lib/validators';
import LeftPointedRadioGroup from '../reusable/elements/radio/LeftPointedRadioGroup.vue';
import apiService from '../../api';
import ScreenshotLoader from '../reusable/forms/ScreenshotLoader.vue';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    idxnum: Number,
    len: Number,
  },
  data: () => ({
    fileLoading: null,
  }),
  validations: {
    value: {
      instaname: { required },
      kind: { required },
      description: { required },
      launch_date: { required },
      price_min: { required },
      price_max: { required },
      turnover: { required },
      processes_involved: { required },
      blogger_can_confirm: { required },
      blogger_coverage: { required },
      screenshots: { required },
    },
  },
  watch: {
    value: {
      deep: true,
      handler(val) {
        console.log(val);
      },
    },
  },
  components: { LeftPointedRadioGroup, ScreenshotLoader },
  computed: {
    ...mapState('producing', ['infoProductsOptions']),
  },
};
</script>
