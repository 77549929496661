<template>
  <div>
    <div class="hovering__pad mt-5 mx-auto p-4" style="max-width: 500px">
      <b-form
        ><b-form-group label="Ваш аккаунт"
          ><b-form-input
            style="height: 40px"
            :class="{ error: $v.producer_profile.instaname.$anyError }"
            v-model="$v.producer_profile.instaname.$model"
          ></b-form-input></b-form-group
        ><b-form-group label="E-mail"
          ><b-form-input
            style="height: 40px"
            :class="{ error: $v.reply.email.$anyError }"
            v-model="$v.reply.email.$model"
          ></b-form-input></b-form-group
        ><b-form-group label="Номер телефона"
          ><b-form-input
            style="height: 40px"
            :class="{ error: $v.reply.phonenumber.$anyError }"
            v-model="$v.reply.phonenumber.$model"
          ></b-form-input></b-form-group
        ><b-form-group label="К какому типу продюсеров вы относитесь">
          <left-pointed-radio-group
            buttonStyle="height: 40px !important"
            :stacked="true"
            :error="$v.producer_profile.producer_type.$anyError"
            v-model="$v.producer_profile.producer_type.$model"
            :options="producerProfileOptions.actions.POST.producer_type.choices"
          ></left-pointed-radio-group></b-form-group
        ><b-form-group
          class="input__symbol--count position-relative mb-5"
          :symbolCount="(producer_profile.completed_courses || '').length"
          :symbolLimit="220"
          label="Какие курсы по продюсированию проходили?"
          ><b-form-textarea
            maxlength="220"
            :class="{ error: $v.producer_profile.completed_courses.$anyError }"
            v-model="$v.producer_profile.completed_courses.$model"
            style="min-height: 180px"
          ></b-form-textarea></b-form-group
        ><b-form-group label="Укажите ваш юридический статус"
          ><legal-status-radio-group
            :options="producerProfileOptions.actions.POST.legal_status.choices"
            v-model="producer_profile"
          ></legal-status-radio-group
        ></b-form-group>
        <b-form-group
          class=""
          inline
          :label="'Какую комиссию с запуска инфопродукта вы берете: ' + commissionDisplay"
          ><b-form-input
            :class="{ error: $v.producer_profile.commission.$anyError }"
            v-model="$v.producer_profile.commission.$model"
            step="5"
            min="5"
            max="50"
            type="range"
            class="easyprbot__slide"
          ></b-form-input
        ></b-form-group>
        <b-form-group>
          <b-form-checkbox
            :class="{ error: $v.reply.percent_agree.$anyError }"
            v-model="reply.percent_agree"
            >Я понимаю, что Easyprbot возьмет агентский процент в случае успешного сотрудничества с
            блогером и запуска инфопродукта, на который я откликаюсь</b-form-checkbox
          ></b-form-group
        >
        <b-form-group label="Заполните ваши кейсы по продюсированию инфопродуктов:">
          <!--b-form-checkbox class="mb-3" size="lg" v-model="nocase"
            >У меня пока нет кейсов по продюсированию</!--b-form-checkbox
          -->
          <div :class="{ 'd-none': nocase }">
            <nested-list-form-container
              ref="ctt"
              v-model="producer_profile.producing_cases"
              :lengthLimit="5"
              :formComponent="ProducingCaseForm"
              :templateObject="producingCaseModel"
            >
            </nested-list-form-container>
            <b-button class="w-100 ph-42" variant="outline-default" @click="$refs.ctt.increment()"
              >Добавить ещё кейс</b-button
            >
          </div>
        </b-form-group>
        <b-form-group label="Сколько проектов вы сейчас одновременно ведете?">
          <left-pointed-radio-group
            buttonStyle="height: 40px !important;"
            :error="$v.producer_profile.simultaneous_projects.$anyError"
            v-model="$v.producer_profile.simultaneous_projects.$model"
            :options="producerProfileOptions.actions.POST.simultaneous_projects.choices"
          ></left-pointed-radio-group>
        </b-form-group>
      </b-form>

      <b-button class="ph-42 w-100" @click="sendRequest" variant="yellow"
        >Отправить запрос блогеру</b-button
      >
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs, requiredIf, email } from 'vuelidate/lib/validators';
import { mapState, mapMutations, mapActions } from 'vuex';
import producing from '@main/api/producing';
import LeftPointedRadioButton from '../reusable/elements/buttons/LeftPointedRadioButton.vue';
import LeftPointedRadioGroup from '../reusable/elements/radio/LeftPointedRadioGroup.vue';
import LegalStatusRadioGroup from '../reusable/elements/radio/LegalStatusRadioGroup.vue';
import NestedListFormContainer from '../reusable/functional/NestedListFormContainer.vue';
import ProducingCaseForm from './ProducingCaseForm.vue';

export default {
  components: {
    LegalStatusRadioGroup,
    LeftPointedRadioGroup,
    NestedListFormContainer,
  },
  props: ['id'],
  data: () => ({
    nocase: false,
    ProducingCaseForm,
    producer_profile: {
      instaname: null,
      producer_type: null,
      completed_courses: null,
      legal_status: 1,
      simultaneous_projects: null,
      commission: 5,
      producing_cases: [],
    },
    reply: {
      email: null,
      phonenumber: null,
      infoproduct: null,
      percent_agree: null,
    },
    producingCaseModel: {
      instaname: null,
      kind: null,
      description: null,
      launch_date: null,
      price_min: null,
      price_max: null,
      turnover: null,
      blogger_coverage: null,
      processes_involved: null,
      screenshots: [],
      blogger_can_confirm: null,
    },
  }),
  validations: {
    producer_profile: {
      instaname: { required },
      producer_type: { required },
      completed_courses: { required },
      legal_status: { required },
      simultaneous_projects: { required },
      commission: { required },
    },
    reply: {
      email: { required },
      phonenumber: { required },
      percent_agree: { required },
    },
  },
  watch: {
    nocase(val) {
      if (val) {
        this.producer_profile.producing_cases = [];
      }
    },
  },
  computed: {
    ...mapState('producing', ['producerProfileOptions']),
    ...mapState(['currentUser']),
    commissionDisplay() {
      if (this.producer_profile.commission < 50) {
        return `${this.producer_profile.commission}%`;
      }
      return '50%+';
    },
  },
  methods: {
    ...mapMutations('notifications', ['showMessage']),
    ...mapActions(['loadUser']),
    async sendRequest() {
      console.log(this.$refs);
      this.$v.$touch();
      const cctValid = this.$refs.ctt.validate();
      if (!this.$v.$anyError && cctValid) {
        if (this.currentUser.producer_profile) {
          await producing.producerProfiles.me.update(this.producer_profile);
        } else {
          await producing.producerProfiles.create({
            ...this.lodash.cloneDeep(this.producer_profile),
            producing_cases: [],
          });
          await this.loadUser();
          await producing.producerProfiles.me.update(this.producer_profile);
        }
        await producing.producerResponse.create(this.reply);
        await this.loadUser();
        this.showMessage({
          title: 'Запрос на продюсирование успешно отправлен!',
          message:
            'Мы свяжемся с вами, если блогер выберет вашу анкету. С уважением, команда Easyprbot.com :)',
          icon: 1,
        });
        this.$router.push({ name: 'reviews' });
      }
    },
  },
  created() {
    if (this.currentUser.producer_profile) {
      this.producer_profile = this.lodash.cloneDeep(this.currentUser.producer_profile);
    }
  },
  mounted() {
    this.reply.infoproduct = Number(this.id);
    this.reply.phonenumber = this.currentUser.profile.phone;
    this.reply.email = this.currentUser.profile.email;
  },
};
</script>

<style lang="scss" scoped>
.fmt-rd {
  width: 48% !important;
  min-width: 110px !important;
}
::v-deep {
  .col-form-label {
    font-size: 16px !important;
  }
  .col-form-label::after {
    content: '*';
    color: red;
    margin-left: 5px;
  }
}
::v-deep .custom-checkbox.b-custom-control-lg,
.input-group-lg .custom-checkbox {
  font-size: 14px !important;
  line-height: 30px;
}
::v-deep .form-group {
  margin-bottom: 2rem;
}
.easyprbot__slide {
}
</style>
