<template>
  <div class="d-flex flex-wrap justify-content-between mb-4">
    <template v-for="option in options">
      <LeftPointedRadioButton
        :key="`${option[valueField]}-o124kirTpFrm43s`"
        :variant="option[valueField] === value ? 'primary' : 'outline-default'"
        @click="
          () => {
            $emit('input', option[valueField]);
          }
        "
        :class="{ error, 'fmt-rd': !stacked, 'w-100': stacked }"
        class="mr-1 mt-2 ph-36 text-left"
        style="line-height: 13px"
        :style="buttonStyle"
      >
        {{ option[textField] }}
      </LeftPointedRadioButton>
    </template>
  </div>
</template>

<script>
import LeftPointedRadioButton from '../buttons/LeftPointedRadioButton.vue';

export default {
  components: { LeftPointedRadioButton },
  props: {
    value: [String, Number],
    options: [Array, Object],
    textField: {
      type: String,
      default: 'display_name',
    },
    valueField: {
      type: String,
      default: 'value',
    },
    error: {
      type: Boolean,
      default: false,
    },
    stacked: Boolean,
    buttonStyle: String,
  },
};
</script>

<style lang="scss" scoped>
.fmt-rd {
  width: 48% !important;
  min-width: 110px !important;
}
</style>
